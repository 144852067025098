import Vue from 'vue'
import VueRouter from 'vue-router'
import Mian from '../layout/main.vue'

import store from '../store/index.js'

import { Message } from 'element-ui';

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject){
    return originalPush.call(this, location, onResolve, onReject)
  }
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

import NProgress from "nprogress"; // 进度条；进度指示器
import "nprogress/nprogress.css"; // 进度条；进度指示器 样式
// 配置
NProgress.inc(0.2);
NProgress.configure({
	easing: "ease",
	speed: 500,
	showSpinner: false
});

const routes = [{
		path: '/',
		name: 'Main',
		component: Mian,
		redirect: '/index',
		children: [{
				path: '/index',
				name: 'index',
				meta: {
					isKey: false,
					needLogin:false,
					isKeep:true,
				},
				component: () => import('../views/index/index.vue')
			},
			{
				path: '/intro',
				name: 'Intro',
				meta: {
					isKey: false,
					needLogin:false,
				},
				component: () => import('../views/intro.vue')
			},
			{
				path: '/expert',
				name: 'expert',
				meta: {
					isKey: false,
					needLogin:false,
				},
				component: () => import('../views/expert.vue')
			},
			{
				path: '/expertDetail',
				name: 'expertDetail',
				meta: {
					isKey: false,
					needLogin:false,
				},
				component: () => import('../views/expertDetail.vue')
			},
			{
				path: '/concat',
				name: 'Concat',
				meta: {
					isKey: false,
					needLogin:false,
				},
				component: () => import('../views/concat.vue')
			},
			{
				path: '/news/:type',
				name: 'News',
				meta: {
					isKey: true,
					needLogin:false,
				},
				component: () => import('../views/news.vue')
			},
			{
				path: '/detail',
				name: 'Detail',
				meta: {
					isKey: true,
					needLogin:false,
				},
				component: () => import('../views/detail.vue')
			},
			{
				path: '/search',
				name: 'search',
				meta: {
					isKey: true,
					needLogin:false,
				},
				component: () => import('../views/search.vue')
			},
			{
				path: '/study/:id',
				name: 'study',
				meta: {
					isKey: true,
					needLogin:false,
					isKeep:false,
				},
				component: () => import('../views/study.vue')
			},
			{
				path: '/video',
				name: 'Video',
				meta: {
					isKey: true,
					needLogin:true,
				},
				component: () => import('../views/video.vue')
			},
			{
				path: '/files',
				name: 'Files',
				meta: {
					isKey: true,
					needLogin:true,
				},
				component: () => import('../views/files.vue')
			},
			{
				path: '/center',
				name: 'Center',
				meta: {
					isKey: false,
					needLogin:true,
				},
				component: () => import('../views/center.vue'),
				redirect: '/center/index',
				children: [{
						path: '/center/index',
						name: 'CenterIndex',
						component: () => import('../views/center/index.vue')
					},
					{
						path: '/center/files',
						name: 'CenterIndex',
						component: () => import('../views/center/files.vue')
					},
					{
						path: '/center/info',
						name: 'center/info',
						component: () => import('../views/center/info.vue')
					},
					{
						path: '/center/testCate',
						name: 'center/testCate',
						component: () => import('../views/center/testCate.vue')
					},
					{
						path: '/center/test3',
						name: 'center/test3',
						component: () => import('../views/center/test2.vue')
					},
					{
						path: '/center/test',
						name: 'center/test',
						component: () => import('../views/center/kaoshi.vue')
					},
					{
						path: '/center/test2',
						name: 'center/test2',
						component: () => import('../views/center/kaoshi2.vue')
					},
					{
						path: '/center/kaoshi_log',
						name: 'center/kaoshi_log',
						component: () => import('../views/center/kaoshi_log.vue')
					},
					{
						path: '/center/fail',
						name: 'center/fail',
						component: () => import('../views/center/fail.vue')
					},
					{
						path: '/center/record2',
						name: 'center/record2',
						component: () => import('../views/center/record2.vue')
					},
					{
						path: '/center/collect',
						name: 'center/collect',
						component: () => import('../views/center/collect.vue')
					},
					{
						path: '/center/record/:type',
						name: 'center/record',
						component: () => import('../views/center/record.vue')
					},
					{
						path: '/center/study/:typeId',
						name: 'center/study',
						component: () => import('../views/center/study.vue')
					},
					{
						path: '/center/case',
						name: 'center/case',
						component: () => import('../views/center/case.vue')
					}
				]
			}
		]
	},
	{
		path: '/login',
		name: 'login',
		meta: {
			isKey: true,
		},
		component: () => import('../views/login.vue')
	},
	{
		path: '/register',
		name: 'register',
		meta: {
			isKey: true,
		},
		component: () => import('../views/register.vue')
	},
	{
		path: '/forget',
		name: 'forget',
		meta: {
			isKey: true,
		},
		component: () => import('../views/forget.vue')
	},
]

const scrollBehavior = (to, from, savedPosition) => {
	if (savedPosition) {
		return savedPosition
	} else {
		return {
			x: 0,
			y: 0
		}
	}
}

const router = new VueRouter({
	mode: 'history',
	routes,
	scrollBehavior
})

router.beforeEach((to, from, next) => {
	let token = window.localStorage.getItem('token')
	if(token){
		store.dispatch('vuex_get_user_info')
		store.commit('setLoginStatus',true)
	}
	
	if(to.meta.needLogin && !store.state.isLogin){
		Message({
			type:'error',
			message:'请登录后再操作',
			duration:1500
		})
		next(to.meta.needLogin ? '/login' : to.fullPath);
	}else{
		NProgress.start();
		next();
	}

});
router.afterEach(() => {
	NProgress.done();
});

export default router
