import router from "./index.js"

export const to_link = (route,params={},type=1)=>{
	if(type == 1){
		router.push({
			path:route,
			query:params
		})
	}
}

export const to_redirect = (route,params={},type=1)=>{
	if(type == 1){
		router.push({
			path:route,
			query:params,
			replace: true
		})
	}else{
		router.push({
			name:route,
			params:params,
			replace: true
		})
	}
}