import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import store from '@/store'

import CONFIG from "@/utils/config.js"

// create an axios instance
const service = axios.create({
  // baseURL: '/api',
  baseURL: CONFIG.baseUrl,
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 20000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
	// console.log(config);
	let token = window.localStorage.getItem('token')
    if (token) {
      config.params['token'] = token
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    const res = response.data

    // if the custom code is not 20000, it is judged as an error.
    return res
  },
  error => {
    console.log('err' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service