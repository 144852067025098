import request from '@/utils/request.js'

// 账号密码登陆
export function login(query={}) {
  return request({
    url: 'api/login',
    method: 'POST',
    params: query
  })
}

//获取用户信息
export function getUserInfo(query={}) {
  return request({
    url: 'api/getUserInfo',
    method: 'POST',
    params: query
  })
}

//注册
export function register(query={}){
	return request({
	  url: 'api/register',
	  method: 'POST',
	  params: query
	})
}

//发送验证码
export function sendSms(query={}){
	return request({
	  url: 'api/sendCode',
	  method: 'POST',
	  params: query
	})
}

//修改密码
export function updatePassword(query={}){
	return request({
	  url: 'api/changePassword',
	  method: 'POST',
	  params: query
	})
}

//获取单页内容
export function getSinglePage(query={}){
	return request({
	  url: 'api/getSinglePage',
	  method: 'POST',
	  params: query
	})
}

//获取网站基本信息
export function getSiteInformation(query={}){
	return request({
	  url: 'api/getSiteInformation',
	  method: 'POST',
	  params: query
	})
}

//获取验证码
export function getCaptcha(query={}) {
	return request({
	  url: 'api/getCaptcha',
	  method: 'POST',
	  params: query
	})
}

//提交留言
export function liuyan(query={}) {
	return request({
	  url: 'api/leaveWord',
	  method: 'POST',
	  params: query
	})
}

//修改用户头像
export function updataUserImage(query={}) {
	return request({
	  url: 'api/updateUserImage',
	  method: 'POST',
	  params: query
	})
}

//修改用户头像
export function updataUserInfo(query={}) {
	return request({
	  url: 'api/updateUserInfo',
	  method: 'POST',
	  params: query
	})
}