<template>
	<div>
		<el-container>
			<el-header>
				<div class="u-container u-flex u-row-between" style="height: 100%;">
					<div class="u-flex">
						<img :src="webInfo.web_logo" />
<!--						<p class="m-head-font1 u-m-l-10">{{webInfo.title}}</p>-->
						<div class="m-head-line"></div>
						<p class="m-head-font2">{{webInfo.name}}</p>
					</div>

					<div class="u-flex">
						<div class="u-flex m-login" v-if="!isLogin" @click="to_link('/login')">
							<img src="../assets/img/common/login-icon.png" />
							<p>学习登陆</p>
						</div>
						<div class="u-flex m-login" v-else @click="to_link('/center')">
							<img src="../assets/img/common/login-icon.png" />
							<p>个人中心</p>
						</div>
						<div class="u-flex m-login" v-else @click="to_link('/info')">
							<img src="../assets/img/common/login-icon.png" />
							<p>修改资料</p>
						</div>
						<div class="u-flex m-login u-m-l-30"  v-if="isLogin" @click="goOut()">
							<img src="../assets/img/common/icon-out.png" />
							<p>退出</p>
						</div>
						<div class="u-flex u-m-l-30 m-code">
							APP下载
							<div class="m-code-position">
								<img :src="webInfo.app_qrcode"/>
							</div>
						</div>
						<div class="m-input u-m-l-26">
							<input v-model="keyword" id="searchInput" />
							<span class="m-input-btn" @click="searchInput">
								<i class="el-icon-search"></i>
							</span>
						</div>
					</div>
				</div>
			</el-header>
			<!-- 菜单 -->
			<div class="m-menu">
				<ul class="u-container">
					<li :class="{'li-active':checkClass('/index')}" @click="selectNav('/')">首页</li>
					<li :class="{'li-active':checkClass('/intro')}" @click="selectNav('/intro')">公司简介</li>
					<li :class="{'li-active':checkClass('News',{type:3},2)}" @click="selectNav('News',{type:3},2)">培训信息</li>
					<!-- <li v-for="(item,index) in typeList" :class="{'li-active':checkClass('study',{id:item.id},2)}" @click="selectNav('study',{id:item.id},2)">{{item.name}}</li> -->
					<li>新闻咨询
						<div class="m-submenu">
							<div @click="selectNav('News',{type:1},2)">新闻咨询</div>
							<div @click="selectNav('News',{type:2},2)">最新咨询</div>
						</div>
					</li>
					<li>培训导航 
						<div class="m-submenu">
							<div v-for="(item,index) in typeList" class="u-line-1" :class="{'li-active':checkClass('study',{id:item.id},2)}" @click="selectNav('study',{id:item.id},2)">{{item.name}}</div>
						</div>
					</li>
					<li :class="{'li-active':checkClass('/expert')}" @click="selectNav('/expert')">专家团队</li>
					<li :class="{'li-active':$route.path == '/concat'}" @click="selectNav('/concat')">联系我们</li>
				</ul>
			</div>
			<!-- 主要内容 -->
			<div class="m-main">
				<template  v-if="$route.meta.isKeep">
				<keep-alive >
					<router-view />
				</keep-alive>
				</template>
				<template v-else>
					<router-view v-if="$route.meta.isKey" :key="key" />
					<router-view v-else />
				</template>
			</div>
			<el-footer>
				<!-- <div class="u-container u-flex u-col-top" style="height: 290px;">
					<div class="m-footer-left">
						<img src="../assets/logo.png" />
						<p>{{webInfo.synopsis ||''}}</p>
					</div>
					<div class="m-footer-line"></div>
					<div class="m-footer-menu u-m-t-80">
						<ul class="u-m-l-55">
							<li @click="selectNav('/')">首页</li>
							<li @click="selectNav('/intro')">公司简介</li>
							<li @click="selectNav('News',{type:3},2)">培训信息</li>
							<li @click="selectNav('News',{type:1},2)">新闻资讯</li>
						</ul>
						<ul class="u-m-l-80">
							<li @click="selectNav('study',{id:1},2)">培训导航</li>
							<li @click="selectNav('/expert')">专家团队</li>
							<li @click="selectNav('/concat')">联系我们</li>
						</ul>
					</div>
					<div class="m-footer-line"></div>
					<div class="m-footer-right">
						<div class="u-flex u-m-t-80">
							<img src="../assets/img/common/icon-tell.png" />
							<span class="u-m-l-26 u-m-r-30">{{webInfo.contact_way}}</span>
							<span>{{webInfo.phone}} </span>
						</div>
						<div class="u-flex u-m-t-30">
							<img src="../assets/img/common/icon-email.png" />
							<span class="u-m-l-26 u-m-r-30">{{webInfo.email}}</span>
						</div>
						<div class="u-flex u-m-t-30">
							<img src="../assets/img/common/icon-web.png" />
							<span class="u-m-l-26 u-m-r-30">{{webInfo.url}}</span>
						</div>
						<div class="u-flex u-m-t-30">
							<img src="../assets/img/common/icon-adress.png" />
							<span class="u-m-l-26 u-m-r-30">{{webInfo.address || ''}}</span>
						</div>
					</div>
				</div> -->
				<div class="m-footer-bottom">
<!--					<span>版权所有 : {{webInfo.copyright}} 网站备案号 : <a target="_blank" :href="webInfo.AQ_url">{{webInfo.AQ}}</a></span>-->
          <!-- 公司信息 -->
          <div class="m-company">
            <div class="u-container m-company-box">
<!--              <div class="m-company-left">
                <img src="../assets/img/common/icon-phone.png" />
              </div>-->
              <div class="m-company-center u-m-l-25">
                <p class="m-company-font1">{{webInfo.company_name ||''}}</p>
                <p class=" m-company-font2 u-m-t-25">
                  <span>联系电话：{{webInfo.contact_way}}</span>
                  <span class="u-m-l-40">手机电话：{{webInfo.phone}} </span>
                  <span class="u-m-l-40">地址：{{webInfo.address || ''}}</span>
                </p>
                <p><span>版权所有 : {{webInfo.copyright}} 网站备案号 : <a target="_blank" :href="webInfo.AQ_url">{{webInfo.AQ}}</a></span></p>
              </div>
<!--              <div class="m-company-right">
                <p class="m-company-font3">联系我们</p>
                <p class="m-company-font4 u-m-t-20">contact us</p>
              </div>-->
            </div>
          </div>
        </div>
			</el-footer>
		</el-container>
	</div>
</template>

<script>
	import {
		to_link,
		to_redirect
	} from '../router/api.js'
	import {getTopCate} from "@/api/index.js"
	import {getSiteInformation} from "@/api/common.js"
	import {
		mapState,
		mapMutations,
		mapActions
	} from 'vuex'
	export default {
		name: 'Mian',
		components: {

		},
		data() {
			return {
				keyword:'',
				typeList:[]
			}
		},
		computed: {
			key() {
				return this.$route.name !== undefined ? this.$route.name + +new Date() : this.$route + +new Date()
			},
			...mapState([
				'user_info',
				'isLogin',
				'webInfo'
			])
		},
		mounted() {
			this.getType()
			this.getWebInfo()
		},
		methods: {
			to_link,
			searchInput(){
				to_link('/search', {
					keyword: this.keyword,
					key: Date.now()
				})
			},
			...mapActions([
				'vuex_out'
			]),
			getWebInfo(){
				getSiteInformation().then(res=>{
					if(res.code == 200){
						document.title = res.data.title || ''
						document.querySelector('meta[name="description"]').content = res.data.describes || ''
						document.querySelector('meta[name="keywords"]').content = res.data.keyword || ''
						this.$store.commit('setWebInfo',res.data)
					}else{
						this.$message.error(res.message)
					}
				})
			},
			checkClass(path,params,type=1){
				let pagePath = this.$route.fullPath
				if(type == 1){
					return pagePath == path
				}else{
					let page2= ''
					for(let i in params){
						page2 = page2 + '/' +params[i]
					}
					return pagePath == `/${path.toLowerCase()}${page2}`
				}
			},
			async getType(){
				if(this.typeList.length > 0) return false;
				let res = await getTopCate()
				if(res.code == 200){
					this.typeList = res.data
				}else{
					this.$message.error(res.message)
				}
			},
			selectNav(path, params = {}, type = 1) {
				let pagePath = this.$route.fullPath
				let routeParams = this.$route.query
				let page2= ''
				if(type == 2){
					for(let i in params){
						page2 = page2 + '/' +params[i]
					}
				}
				
				if ((type == 1 && pagePath == path ) || (type == 2 && pagePath == `/${path.toLowerCase()}${page2}`)) return false;
				to_redirect(path, params,type)
			},
			goOut() {
				this.$confirm('确认退出吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.vuex_out()
					
				})
			}
		}
	}
</script>

<style lang="scss">
.m-company {
  margin-top: 76px;
  width: 100%;
  background: #385d7c;
  padding: 30px 0;
  .m-company-font1 {
    line-height: 24px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
  }

  .m-company-font2 {
    line-height: 16px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #CFCFCF;
  }

  .m-company-font3 {
    line-height: 24px;
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
  }

  .m-company-font4 {
    line-height: 20px;
    font-size: 20px;
    font-family: Arial;
    font-weight: 400;
    color: #FFFFFF;
  }

  .m-company-box {
    display: flex;
    justify-content: space-between;

    .m-company-left {
      display: flex;
      width: 136px;
      height: 136px;
      background: #42ACDC;
      justify-content: center;
      align-items: center;
    }

    .m-company-center {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: center;
    }

    .m-company-right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #fff;
      width: 220px;
      height: 136px;
      background: #42ACDC;
    }
  }
}
	.el-header {
		height: 80px !important;
		background: url('../assets/img/common/header-bg.png');
	}

	.el-footer {
		height: 50px !important;
/*		background: #403F45;*/
		padding: 0 !important;
	}
</style>
<style lang="scss" scoped>
	.m-main {
		min-height: calc(100vh - 460px);
	}
	
	.m-code{
		position: relative;
		font-size: 16px;
		line-height: 16px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #545454;
		cursor: pointer;
		.m-code-position{
			display: none;
			position: absolute;
			top: 0;
			width: 220px;

			z-index: 100;
			img{
				width: 100%;
				height: 100%;
			}
		}
		&:hover{
			.m-code-position{
				display: block;
			}
		}
	}

	.m-menu {
		width: 100%;
		height: 40px;
		background: #1b87ef;

		ul {
			display: flex;

			li {
				display: flex;
				height: 40px;
				line-height: 40px;
				padding: 0 24px;
				color: #fff;
				font-size: 16px;
				cursor: pointer;
				position: relative;
				margin-right: 60px;
				flex-shrink: 0;
				flex-grow: 0;

				&:hover {
					background: #1b87ef;

					.m-submenu {
						display: block;
					}
				}

			}

			.li-active {
				background: #1b87ef;
			}
		}

		.m-submenu {
			display: none;
			width: 112px;
			position: absolute;
			top: 40px;
			left: 0;
			z-index: 10;
			background: #fff;

			div {
				width: 112px;
				height: 40px;
				text-align: center;
				line-height: 40px;
				background: #1b87ef;
				font-size: 16px;
				color: #fff;
				padding: 0 10px;
			}
		}
	}

	.m-head-font1 {
		font-size: 30px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #000000;
		line-height: 30px;
	}

	.m-head-font2 {
		font-size: 22px;
		color: #797979;
		line-height: 22px;
	}

	.m-head-line {
		width: 1px;
		height: 44px;
		background: #D0D0D0;
		margin: 0 12px;
	}

	.m-login {
		font-size: 16px;
		line-height: 16px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #545454;
		cursor: pointer;

		img {
			margin-right: 6px;
		}
	}

	.m-input {
		display: flex;
		width: 238px;
		height: 30px;

		input {
			width: 170px;
			height: 28px;
			outline: none;
			border: 1px solid #E3E3E3 !important;
			border-right: 0;
			padding: 0 10px;
		}

		.m-input-btn {
			display: block;
			width: 48px;
			height: 30px;
			background: #F8A310;
			color: #fff;
			border-radius: 0;
			border: 0;
			font-size: 20px;
			cursor: pointer;
			text-align: center;
			line-height: 30px;
		}
	}

	.m-footer-left {
		width: 360px;
		margin-top: 55px;
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #ACACAC;
		line-height: 26px;

		P {
			margin-top: 24px;
			margin-left: 12px;
		}
	}

	.m-footer-line {
		width: 1px;
		height: 200px;
		margin-top: 70px;
		margin-left: 70px;
		background: linear-gradient(#403F45, #ACACAC, #403F45);
	}

	.m-footer-menu {
		display: flex;

		ul {
			display: flex;
			flex-direction: column;

			li {
				display: flex;
				width: 64px;
				font-size: 16px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #ACACAC;
				line-height: 16px;
				margin-bottom: 16px;
				cursor: pointer;
			}
		}
	}

	.m-footer-right {
		display: flex;
		flex-direction: column;
		margin-left: 40px;
		font-size: 16px;
		color: #ACACAC;
		line-height: 16px;
	}

	.m-footer-bottom {
		height: 50px;
		box-sizing: border-box;
		// border-top: 1px solid #6C6C6C;
		text-align: center;
		line-height: 49px;
		color: #fff;
		background: #1b87ef;
		font-size: 16px;
		a{
			text-decoration: none;
			color: #fff;
		}
	}
</style>
