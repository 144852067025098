import Vue from 'vue'
import Vuex from 'vuex'

import router from '../router'
import {getUserInfo} from "@/api/common.js"
import {
	Message
} from 'element-ui';

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		isLogin: false, //是否登录
		user_info: {},
		majorList:[
			{title:'初级',value:'1'},
			{title:'中级',value:'2'},
			{title:'高级',value:'3'}
		],//专业列表
		webInfo:{}
	},
	mutations: {
		//设置登陆状态
		setLoginStatus(state, status = true) {
			state.isLogin = status
		},
		//设置用户信息
		setUserInfo(state, info = {}) {
			state.user_info = info
		},
		//设置网站基本信息
		setWebInfo(state,info={}){
			state.webInfo = info
		}
	},
	actions: {
		async vuex_get_user_info(context){
			let res = await getUserInfo()
			console.log(res,'getUserInfo321');
			if(res.code == 50004){
				Message({
					type: 'error',
					message: '用户登录过期请重新登录',
					duration: 1500
				})
				window.localStorage.removeItem('token')
				context.commit('setLoginStatus', false)
				router.replace({
					path:'/login'
				})
				return false;
			}
			if(res.code == 200){
				context.commit('setLoginStatus', true)
				context.commit('setUserInfo', res.data)
				return false
			}
			Message({
				type: 'error',
				message: res.message,
				duration: 1500
			})
			
		},
		//退出
		vuex_out(context){
			//退出登陆
			Message({
				type: 'success',
				message: '退出成功',
				duration: 1500
			})
			
			window.localStorage.removeItem('token')
			context.commit('setLoginStatus', false)
			context.commit('setUserInfo')
			router.replace({
				path:'/'
			})
		},
	},
	modules: {}
})
