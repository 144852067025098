import request from '@/utils/request.js'

//获取首页分类数据
export function getTopCate(query={}) {
  return request({
    url: 'api/getTopCate',
    method: 'POST',
    params: query
  })
}

//获取banner图
export function getBanners(query={}) {
	return request({
	  url: 'api/getBanners',
	  method: 'POST',
	  params: query
	})
}

//获取课程
export function getCourseForCateId(query={}) {
	return request({
	  url: 'api/getCourseForCateId',
	  method: 'POST',
	  params: query
	})
}

//获取课程详情
export function getCourseDetail(query = {}){
	return request({
	  url: 'api/getCoursePathForCourseId',
	  method: 'POST',
	  params: query
	})
}

//获取专家团队
export function getExpertTeam(query={}) {
	return request({
	  url: 'api/getExpertTeam',
	  method: 'POST',
	  params: query
	})
}

//获取友情链接 
export function getBlogroll(query={}) {
	return request({
	  url: 'api/getBlogroll',
	  method: 'POST',
	  params: query
	})
}

export function search(query={}){
	return request({
	  url: 'api/search',
	  method: 'POST',
	  params: query
	})
}

//获取shouye
export function IndexBanner(query={}) {
	return request({
	  url: 'api/IndexBanner',
	  method: 'POST',
	  params: query
	})
}