<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
	export default {
		data(){
			return {
				title:'',
				keywords:'',
				description:''
			}
		},
		created() {
			isMobile()
		},
		methods:{
			
		}
	}
	function isMobile(){
	 if(window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
	  window.location.href = 'http://mobile.yubo327.com/'
	  return true; // 移动端
	 }else{
		 // window.location.href = 'http://yubo327.com'
	  return false; // PC端
	 }
	}
</script>
<style lang="scss">
	@import './assets/style/uview/common.scss';
	@import './assets/style/uview/style.vue.scss';
	*{
		margin: 0;
		padding: 0;
	}
	.u-container{
		width: 1200px;
		margin: 0 auto;
	}
	#nprogress .bar {
	    background: rgba(0,189,11,1) !important; //自定义颜色
	}
	.el-message{
		top: 120px!important;
		min-width: 150px!important;
	}
</style>
